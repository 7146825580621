import React from "react";
import ReactDOM from 'react-dom';
import { warn, isKey } from "../common/utils";
import _, { takeRightWhile } from "lodash";

export default class NavigatableFrame extends React.Component
{
    constructor(props)
    {
        super(props);

        document.navigatableFrame = this;

        this.components = [];

        this.selectedTabIndex2D = {
            x: 0,
            y: 0
        }
    }

    debugShowComponents()
    {
        console.group("[arcadeui]: navigatableFrame");

        for (let elem of document.navigatableFrame.components)
            console.log(elem.props.tabIndex2D);

        // for (let elem of document.navigatableFrame.components)
            // console.log(elem?.ref?.current);

        console.groupEnd();
    }

    onFocusChange(component)
    {
        
        //Not a navigable component!
        if(component?.props == null || component.props?.tabIndex2D == null)
            return;
        
        this.selectedTabIndex2D = component.props.tabIndex2D;
        // console.log(`tabIndex2D updated: ${JSON.stringify(this.selectedTabIndex2D)}`);
    }

    onPromptedNavigationEvent(event, caller)
    {
        // console.log("navigation event: ");
        // console.log(caller.props.tabIndex2D);

        let tabIndices = this.components.map(x => x.props?.tabIndex2D);

        let targetPos = { ...this.selectedTabIndex2D };

        let conditional = null;

        if (isKey(event.detail.which).right)       { targetPos.x += 1; conditional = (x) => (x.x >= targetPos.x); } 
        else if (isKey(event.detail.which).left)   { targetPos.x -= 1; conditional = (x) => (x.x <= targetPos.x); }
        else if (isKey(event.detail.which).up)     { targetPos.y -= 1; conditional = (x) => (x.y <= targetPos.y); }
        else if (isKey(event.detail.which).down)   { targetPos.y += 1; conditional = (x) => (x.y >= targetPos.y); }

        //Filter before best match
        tabIndices = tabIndices.filter(x => conditional(x));

        //None which match? Get out of here
        if(tabIndices.length <= 0)
            return;
        
        let d = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

        let c = 1;

        if (this.selectedTabIndex2D.x != targetPos.x)
            d = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + (Math.pow(a.y - b.y, 2) * c));

        else if (this.selectedTabIndex2D.y != targetPos.y)
            d = (a, b) => Math.sqrt((Math.pow(a.x - b.x, 2) * c) + Math.pow(a.y - b.y, 2));
        
        tabIndices = tabIndices.map(a => { return { x: a.x, y: a.y, dist: d(a, targetPos) }});
        tabIndices = _.sortBy(tabIndices, x => x.dist);

        // console.log(targetPos);
        // console.log(tabIndices[0]);
        // console.log("-------");

        let selectedPos = tabIndices[0];
        let componentIndex = this.components.findIndex(x => (x.props.tabIndex2D.x == selectedPos.x) && (x.props.tabIndex2D.y == selectedPos.y));

        if(componentIndex < 0)
            return warn(`Tried shifting focused to element with tab index 2D: ${selectedPos} but couldnt find it.`);


        this.components[componentIndex].ref?.current.focus();
    }

    getRefedComponent(index)
    {
        //Is there an OOBE?
        if(index < 0 || index > this.components.length)
            return warn(`Tried to get component ${index} when there are only ${this.components.length} navigable components.`) || null;

        //Otherwise return the component
        return this.components[index].ref?.current;
    }

    focusDefaultComponent()
    {
        if (this.components.length <= 0)
            return;

        this.onFocusChange(this.components[0]);
        this.components[0].ref.current.focus();
    }

    unregisterNavigableComponent(component)
    {
        if(component.state.focused)
        {
            //This component is focused and we're going to unregister it. Therefore, we need to do
            //something like set the default focus to some element. In this case, we opt to focus on
            //the first element.

            this.focusDefaultComponent();
        }

        //Get index into the array
        let index = this.components.indexOf(component);

        //Invalid index? die
        if(index < 0)
            return warn("Trying to unregister component when it wasn't found in the list of registered components.") || null;

        //Otherwise remove it
        this.components.splice(index, 1);
    }

    registerNavigableComponent(component)
    {
        this.components.push(component);

        if(this.components.length == 1)
        {
            //This is the first component to be added, perhaps after a massive
            //amount of changes to the page. So focus on default element.
            this.focusDefaultComponent();
        }
    }

    componentDidMount()
    {   
        this.focusDefaultComponent();
    }

    render()
    {   
        return this.props.children;
    }
}