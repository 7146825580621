import axios from "axios";
import strapiConfig from '../config/api/strapiConfig';

export default
{
    getAllPages()
    {
        //Build request URL
        const requestURL = strapiConfig.baseURL + strapiConfig.endpoints.aboutPages;

        //Send it
        return new Promise((resolve, reject) => {
            axios.get(requestURL, strapiConfig.requestConfig).then(function(response)
            {
                if(response.status != 200)
                    return reject(`Invalid status: ${response.status}`);

                const responseData = response.data;

                const returnedPages = responseData.data;

                resolve(returnedPages);
            });
        });
    },

    getAllPagesSeparated()
    {
        return new Promise((resolve, reject) => {
            this.getAllPages().then(function(data)
            {
                resolve({ 
                    titles: data.map(x => x.attributes.title),
                    descriptions: data.map(x => x.attributes.description)
                });
            });
        });
    }
}