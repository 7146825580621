import $ from 'jquery';
import React from "react";
import ReactDOM from "react-dom";

//Import arcade UI library
import NavigatableFrame from './arcadeui/js/components/NavigatableFrame.jsx';
import TestTitleStyles from './arcadeui/js/debug/TestTitleStyles';
import { TestSwitcher } from './arcadeui/js/debug/TestInputElements';

//Import routing
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';

//Import sass
import './arcadeui/sass/main.sass'
import './sass/main.sass';
import GridMenu from './arcadeui/js/components/GridMenu.jsx';
import Menu from './arcadeui/js/components/Menu.jsx';
import Label from './arcadeui/js/components/Label.jsx';
import Stack from './arcadeui/js/components/Stack.jsx';
import Table from './arcadeui/js/components/Table.jsx';
import Statistic from './arcadeui/js/components/Statistic.jsx';
import ControlIcon from './arcadeui/js/components/ControlIcon.jsx';
import Button from './arcadeui/js/components/Button.jsx';
import Confirmation from './arcadeui/js/components/Confirmation.jsx';
import StartPage from './pages/StartPage.jsx';
import GamesPage from './pages/GamesPage.jsx';
import MainMenu from './pages/MainMenu.jsx';
import AboutPage from './pages/AboutPage.jsx';


class App extends React.Component
{
	constructor(props)
	{
		super(props);

		let randomItems = "abcdefghijklmnopqrstuvwxyz".split("");
		
		let tableHeaders = ["Position", "Name", "Score"];

		let tableData = [];

		for(let i = 0; i < 25; i++)
			tableData.push([i, _.shuffle("benjamin"), Math.floor(Math.random() * 2000000)]);

		this.state = {
			components: [ 
				<TestSwitcher key={0} />,
				// <Menu tabIndex2D={{ x: 0, y: 12 }} items={[ "Start", "Options", "Exit" ]} orientation="horizontal" key={3} />,
				<Menu tabIndex2D={{ x: 0, y: 13 }} items={[ "Start", "Options", "Exit" ]} orientation="vertical" key={2} />,
				<Stack orientation="horizontal" key={Math.random() * 5000}>
					<Label title="LABEL 1" />
					<Label faded title="LABEL 2" />
					<Label error title="LABEL 3" />
				</Stack>,
				<Stack dividers orientation="horizontal" key={23532}>
					<ControlIcon color={2} control="A" title="Jump" vertical />
					<ControlIcon color={3} control="START" title="Start game" />
					<ControlIcon color={1} control="B" title="Shoot" />
					<ControlIcon color={4} control="<" title="Move left" />
				</Stack>,
				<Stack dividers orientation="horizontal" key={23525}>
					<Button tabIndex2D={{ x: 0, y: 16 }} title="Do something"/>
					<Button style={3} tabIndex2D={{ x: 1, y: 16 }} title="< Go back"/>
					<Button style={2} tabIndex2D={{ x: 2, y: 16 }} title="Next >"/>
				</Stack>,
				<Confirmation key={99199} options={["Cancel", "Okay"]} tabIndex2D={{ x: 0, y: 17 }} />
				// <Statistic valueFirst dimmed noBorder title="Times played" value={(13536).toLocaleString()} key={23982893} />
				// <Table headers={tableHeaders} data={tableData} key={35235} />
			]
		}
	}

	componentDidMount()
	{
		const ctx = this;

		document.addEventListener("keyup", function(event)
		{
			if(event.code == "KeyE")
				document.navigatableFrame.debugShowComponents();
		})
	}
	
	render()
	{
		return <NavigatableFrame>
			<Router>
				<Routes>
					<Route path="/">
						<Route index element={<StartPage />}/>
						<Route path="arcade">
							<Route index element={<MainMenu/>}/>
							<Route path="games" element={<GamesPage/>}/>
							<Route path="about" element={<AboutPage/>}/>
						</Route>
					</Route>
				</Routes>
			</Router>
		</NavigatableFrame>

		// return <NavigatableFrame>
		// 	<Router>
		// 		<Switch>
		// 			<Route path="/howdy">
		// 				<b>fuck off</b>
		// 			</Route>
		// 			<Route path="/there">
		// 				<b>fuck off as well</b>
		// 			</Route>
		// 		</Switch>
		// 	</Router>
		// 	{/* {this.state.components} */}
		// </NavigatableFrame>
	}
}

ReactDOM.render(<App />, document.getElementById("AppContainer"));