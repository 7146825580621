import strapiApi from "./strapiApiToken";

export default {
    requestConfig: {
        headers: { 
            Authorization: `Bearer ${strapiApi.serviceToken}`
        }
    },
    baseURL: "http://10.5.11.126:1337/api/",
    mediaBaseURL: "http://10.5.11.126:1337",
    endpoints: {
        aboutPages: "about-pages",
        splashScreenConfig: "splash-screen-config?populate=*",
        mainMenuConfig: "main-menu-config?populate=*",
        games: "games?populate=*"
    }
}