import React from "react"
import ReactDOM from 'react-dom'
import Switcher from "../components/Switcher.jsx"

export function TestSwitcher()
{
    return <div className="test-div">
        <p>Select something</p>
        <Switcher tabIndex2D={{ x: 0, y: 1 }} items={["Apples", "Oranges", "Pears", "Plums"]}/>

        <p>Another switcher</p>
        <Switcher tabIndex2D={{ x: 0, y: 2}} items={["Page 1", "Page 2", "Page 3"]}/>
    </div>
}