import axios from "axios";
import strapiConfig from '../config/api/strapiConfig';

export default
{
    parseGames(data)
    {
        let games = data.map(x => x.attributes);

        for(let i = 0; i < games.length; i++)
        {
            games[i].gameAuthors = games[i].gameAuthors.data.map(x => x.attributes);
            games[i].gameGenres  = games[i].gameGenres.data.map(x => x.attributes);
            
            if(games[i].splashScreen)
                games[i].splashScreen = games[i].splashScreen.data.attributes;
        }

        return games;
    },

    getAllGames() 
    {
        //Build request URL
        const requestURL = strapiConfig.baseURL + strapiConfig.endpoints.games;

        const ctx = this;

        //Send it
        return new Promise((resolve, reject) => 
        {
            axios.get(requestURL, strapiConfig.requestConfig).then(function (response) 
            {
                if (response.status != 200)
                    return reject(`Invalid status: ${response.status}`);

                const responseData = response.data;

                let returnedPages = responseData.data;

                returnedPages = ctx.parseGames(returnedPages);

                resolve(returnedPages);
            });
        });
    }
}