import React from "react";
import { warn } from "../common/utils";


export default class ControlIcon extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    getControlIcon(v)
    {
        if(v.length > 1)
            return <div className="icon-wrapper">
                <div className="icon">
                    <span>-</span>
                </div>
                <p>{v}</p>
            </div>

        return <div className="icon">
            <span>{v}</span>
        </div>
    }
    
    getTitle()
    {
        if(this.props?.title == null)
            return null;

        return <p>{this.props.title}</p>;
    }

    render()
    {
        if(this.props?.control == null)
            return warn("Control not supplied to ControlIcon when rendering.");

        let classes = [];

        if(this.props?.color)
            classes.push("col-" + this.props.color);
        else
            classes.push("col-1");

        if(this.props?.vertical)
            classes.push("vertical");

        return <div className={`control-icon ${classes.join(" ")}`}>
            {this.getControlIcon(this.props.control)}
            {this.getTitle()}
        </div>
    }
}