import React from 'react';
import { warn } from '../common/utils';
import { makeNavigable } from './makeNavigable.jsx'
import MenuItem from './MenuItem.jsx';

export default class GridMenu extends React.Component
{
    constructor(props)
    {
        super(props);

        // console.log(props.items);

        this.itemStatus = [];
    }

    componentDidUpdate()
    {
        this.itemStatus = this.props?.items?.map((x, i) => {
            return {
                index: i,
                highlighted: false
            }
        });

        if(this.itemStatus.length > 0)
            this.itemStatus[0].highlighted = true;

    }

    onSelectionUpdated()
    {
        const ctx = this;

        let selection = this.itemStatus.map((x, i) => { 
            return {...x, index: i }
        })
        .filter(x => x.highlighted)
        .map((x,i) => {
            return {
                ...x,
                title: ctx.props.items[x.index]
            }
        })

        if(this.props?.onSelectionUpdated != null)
            this.props.onSelectionUpdated(selection);
    }

    getSelectMode()
    {
        if(this.props?.selectMode == null || this.props?.selectMode == "single")
            return "single";

        return "multiple";
    }

    onEnterPressed(event)
    {
        let selectMode = this.getSelectMode();

        let component = (event.detail.thisComponent);
        let index = this.props.items.findIndex(x => x == component.props.title);

        let itemStatusCopy = [...this.itemStatus];

        if(selectMode == "single")
        {
            for(let i = 0; i < itemStatusCopy.length; i++)
                itemStatusCopy[i].highlighted = false;
        }

        itemStatusCopy[index].highlighted = !itemStatusCopy[index].highlighted;
        this.onSelectionUpdated();
        
        this.setState({ itemStatus: itemStatusCopy });
    }

    onArrowPressed(event)
    {
        // console.log(event);
    }

    render()
    {
        let columns = 5;

        if(this.props?.columns == null)
            warn(`Trying to render GridMenu but number of columns is not specified. Using ${columns}.`);
        else
            columns = this.props.columns;

        let ctx = this;

        return <div className={`menu grid col-${columns}`}>
            {this.props?.items?.map((x,i) => {
                
                const gx = Math.floor(i / 16);
                const gy = i % 16;
                // const gx = i % columns;
                // const gy =  Math.floor(i / columns);


                if(ctx.itemStatus[i] && ctx.itemStatus[i]?.highlighted)                
                    return <MenuItem onArrowPressed={this.onArrowPressed.bind(this)} onEnterPressed={this.onEnterPressed.bind(this)} highlighted title={x} key={i} tabIndex2D={{ x: ctx.props?.tabIndex2D.x + gx, y: ctx.props?.tabIndex2D.y + gy }} />;

                return <MenuItem onArrowPressed={this.onArrowPressed.bind(this)} onEnterPressed={this.onEnterPressed.bind(this)} title={x} key={i} tabIndex2D={{ x: ctx.props?.tabIndex2D.x + gx, y: ctx.props?.tabIndex2D.y + gy }} />;
            })}
        </div>
    }
}