import React from 'react';
import { warn } from '../common/utils';
import { makeNavigable } from './makeNavigable.jsx'
import MenuItem from './MenuItem.jsx';

export default class Menu extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    onItemPressEnter(data)
    {
        //Null -- get out of here
        if(this.props?.onEnterPressedItem == null)
            return;

        let element = data.detail.thisElement;
        let text = element.innerText;

        let index = this.props.items.findIndex(x => x == text);

        //Otherwise call callback
        this.props.onEnterPressedItem(data, index);
    }

    render()
    {
        if(this.props?.orientation == null)
            warn(`Trying to render Menu but orientation is not specified. Using default.`);

        let ctx = this;

        let classStr = (this.props?.orientation == "horizontal") ? ("horizontal") : ("vertical");

        let addValues = {
            x: +(this.props?.orientation == "horizontal"),
            y: +(this.props?.orientation != "horizontal")
        }

        return <div className={`menu ${classStr}`}>
            {this.props?.items?.map((x,i) => {
                return <MenuItem onEnterPressed={this.onItemPressEnter.bind(this)} title={x} key={i} tabIndex2D={{ x: ctx.props?.tabIndex2D.x + addValues.x * i, y: ctx.props?.tabIndex2D.y + addValues.y * i }} />;
            })}
        </div>
    }
}