

export default {
    navigation:
    {
        mainmenu:
        [
            {
                title: "Games Library",
                target: "/arcade/games"
            },

            {
                title: "About",
                target: "/arcade/about",
            },

            // {
            //     title: "Report an issue",
            //     target: "/report-issue"
            // },

            // {
            //     title: "Get involved",
            //     target: "/get-involved"
            // },

            {
                title: "Exit",
                target: "/"
            }
        ]
    }
}