import React from 'react'
import ReactDOM from 'react-dom'
import { makeNavigable } from './makeNavigable.jsx';

//Config
import componentConfig from '../../config/components'
const config = componentConfig.switcher;

//Utils
import { wrapValue, warn, isEventMine, isKey } from '../common/utils';

class Switcher extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            itemIndex: 0
        };
    }

    componentDidMount()
    {
        document.addEventListener("arrowpress", this.onArrowPress.bind(this))
    }

    onArrowPress(event)
    {
        //Is this event not this element? Just return
        if(!isEventMine(event, this))
            return;

        //Otherwise its intended for this element! Cool!
        //..

        //Left pressed? Do stuff
        if (isKey(event.detail.which).left)
            this.incrementItemIndex(-1);

        //Right pressed? Do stuff
        else if (isKey(event.detail.which).right)
            this.incrementItemIndex(+1);

        //Otherwise.. emit to navigatable frame
        else
            document.navigatableFrame.onPromptedNavigationEvent(event, this);
    }

    incrementItemIndex(incrementValue = 1)
    {
        //Is this disabled? If so, don't do anything
        //..

        if(this.props?.disabled)
            return;

        const newIndex = wrapValue(this.state.itemIndex + incrementValue, this.props.items.length);
          
        this.setState({ 
            itemIndex: newIndex
        });

        if (this.props?.onIndexChanged != null)
            this.props.onIndexChanged(newIndex);
    }

    getClasses()
    {
        let classes= ["switcher"];

        if (this.props?.disabled)
            classes.push("disabled");

        else if (this.props?.selected)
            classes.push("selected");

        return classes;
    }

    render()
    {
        //No items passed
        if(this.props?.items == null)
            return warn("No 'items' prop passed to Switcher component.") || null;
            
        let classes = this.getClasses();

        const getCurrentItemText = () => this.props.items[this.state.itemIndex];

        let title = `(${this.props?.tabIndex2D?.x}, ${this.props?.tabIndex2D?.y})`;

        return <div className={classes.join(" ")} title={title} >
            <div className="glyph left-glyph">{config.leftGlyph}</div>
            <div className="text">{getCurrentItemText()}</div>
            <div className="glyph right-glyph">{config.rightGlyph}</div>
        </div>
    }
}

export default makeNavigable(Switcher);