import React from 'react';
import pkg from '../../package.json'

const organisation = "University of Lincoln";

const uiCfg = {
    uiPackageName: pkg.name,
    uiPackageVersion: pkg.version
}
const uiAuthors = [ "Benjamin Williams" ];

const arcadeDates = {
    startYear: 2018,
    endYear: (new Date()).getFullYear()
}

const copyrightNotice = `Copyright \u00A9 ${arcadeDates.startYear} - ${arcadeDates.endYear} ${organisation}. All rights reserved.`;

const hash = COMMITHASH.slice(0, 7);
export default { 
    mainMenu: {
        copyrightNotice
    },

    startPage: {
        title: "Press Start",
        subtitle: <>Use the <kbd>joystick</kbd> to move</>,
        showLogo: true,
        logoPath: "assets/logo.png"
    },

    global: {
        showCRTFilter: false
    },

    aboutPage: {
        copyrightNotice,
        footerNotice: <span>
            {copyrightNotice}<br/><br/>
            {`[${uiCfg.uiPackageName}]`}
            {` developed by ${uiAuthors.join(", ")}`}<br/>
            {`[${VERSION}] [${hash} on ${BRANCH}]`}
        </span>,

        credits: [
            { para: "The following is a list of contributors to either the Arcademia or Research Arcade projects. Contributors and contribution areas below are listed in no particular order." },
            { gap: true },
            { big: true, title: "Arcademia", name: ["(2022-)"] },
            { para: "The Arcademia project focuses on showcasing video games developed by the student cohort in the University of Lincoln. A successor to The Research Arcade project, its emphasis is shifted towards showcasing student work rather than collecting valuable data for research purposes." },
            { title: "Project Design", name: ["Benjamin Williams", "Matt Ashton", "Oliver Grooby", "Kieran Hicks"] },
            { title: "Resource Lead / Management", name: ["Matt Ashton"] },
            { title: "Back-end / Dockerisation / Support", name: ["Oliver Grooby"] },
            { title: "Front-end / Back-end development", name: ["Benjamin Williams"] },
            { title: "Architecture Lead / Design", name: ["Benjamin Williams"] },
            { title: "UI/ UX Support", name: ["Ally Garton", "Bethany Davies", "Oliver Grooby", "Kieran Hicks" ] },
            { title: "Game Developers", name: ["Joe McLellland", "Benjamin Williams"] },
            { title: "Original Cabinet Design", name: ["Chris Headleand"] },
            { title: "Original Cabinet Build", name: ["Chris Headleand", "Jeff Pashley", "Alex Chapman"] },
            //----
            { gap: true },
            { big: true, title: "The Research Arcade", name: ["(2018-2022)"] },
            { img: "assets/enemy-4.gif" },
            { para: "The Research Arcade project focused on student-created video games for research purposes. It was an experimental University of Lincoln project, running from 2018 to its closure in 2022. " },
            { title: "Project Lead", name: ["Chris Headleand"] },
            { title: "Cabinet Design", name: ["Chris Headleand"] },
            { title: "Architecture Lead", name: ["Benjamin Williams"] },
            { title: "Cabinet Build", name: ["Chris Headleand", "Jeff Pashley", "Alex Chapman"] },
            { title: "Technical Support", name: ["Matt Ashton", "Jeff Pashley" ] },
            { title: "Game Developers", name: ["Ben Hide", "Benjamin Williams", "Chris Ross", "Marlon Gilliam", "Tom Smith", "Alex Chapman", "Jake Harrington"] },
            { title: "Special thanks", name: ["Kieran Hicks", "Bethany Davies", "Carla Taylor-Rutterford"] },
            { gap: true },
            { para: "An extra thanks goes to everybody who has engaged with this project, including you!" },
        ]
    }
}