import _ from "lodash";
import React from "react";
import { makeNavigable } from "./makeNavigable.jsx";
import { isEventMine, isKey } from "../common/utils.js";

class Button extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    componentDidMount() 
    {
        document.addEventListener("arrowpress", this.onArrowPress.bind(this));
        document.addEventListener("enterpress", this.onEnterPress.bind(this));
    }

    onEnterPress(event)
    {
        //Is this event not this element? Just return
        if (!isEventMine(event, this))
            return;

        //Call callback
        if (this.props?.onEnterPressed != null)
            this.props.onEnterPressed(event);
    }

    onArrowPress(event) 
    {
        //Is this event not this element? Just return
        if (!isEventMine(event, this))
            return;

        //Otherwise.. emit to navigatable frame
        document.navigatableFrame.onPromptedNavigationEvent(event, this);
    }

    toHTMLProp(key, value) 
    {
        return {
            [`data-${key}`]: value
        }
    }

    render()
    {
        if(this.props?.tabIndex2D == null)
            return warn("Tab index 2D not supplied to Button on rendering.");

        let htmlProps = {
            ...this.toHTMLProp("ti-x", this.props.tabIndex2D.x),
            ...this.toHTMLProp("ti-y", this.props.tabIndex2D.y),
        };

        let classes = [];
        let style = 1;

        if(this.props?.style)
            style = this.props.style;

        if (this.props.selected)
            classes.push("selected");

        classes.push("style-" + style);
        classes = classes.join(" ");

        let title = `(${this.props.tabIndex2D.x}, ${this.props.tabIndex2D.y})`;

        return <button {...htmlProps} title={title} className={`button ${classes}`}>
            {this.props.title}
        </button>
    }
}

export default makeNavigable(Button);