import React from "react"
import ReactDOM from 'react-dom'

export default function TestTitleStyles()
{
    return <div className="test-div">
        <h1 className="uppercase dimmed">This is a h1 title</h1>
        <h2 className="selected">This is a h2 title</h2>
        <h3 className="accented">This is a h3 title</h3>
        <p>This is a test paragraph</p>
    </div>
}