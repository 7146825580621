import React from "react";
import { warn } from "../common/utils";


export default class Table extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    getTableHeader()
    {
        return <thead>
            <tr>
                {this.props.headers.map((x, i) => <td key={i}>{x}</td>)}
            </tr>
        </thead>
    }

    getTableRows()
    {
        return <tbody>
            {this.props.data.map((row, i) => {
                return <tr key={i}>
                    {row.map((col, j) => { return <td key={j}>{col}</td>; })}
                </tr>
            })}
        </tbody>
    }

    render()
    {
        //Null? Get out of here
        if(this.props?.data == null || this.props?.headers == null)
            return warn("Either data or headers wasn't set when rendering Table component.");

        //Otherwise render a table
        return <table className="data-table">
            {this.getTableHeader()}
            {this.getTableRows()}
        </table>

    }
}