import React from 'react'
import Switcher from '../arcadeui/js/components/Switcher.jsx';
import Stack from '../arcadeui/js/components/Stack.jsx';
import GridMenu from '../arcadeui/js/components/GridMenu.jsx';
import Button from '../arcadeui/js/components/Button.jsx';
import Label from '../arcadeui/js/components/Label.jsx';
import CRTOverlay from '../arcadeui/js/components/CRTOverlay.jsx';

//Router stuff
import { useNavigate } from 'react-router-dom';
import pageText from '../config/pageText.js';
import ControlIcon from '../arcadeui/js/components/ControlIcon.jsx';

//Api stuff
import GamesAPI from '../models/Games'
import strapiConfig from '../config/api/strapiConfig.js';

class GamesPage extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            shownGame: null,
            allGameData: []
        }

        const ctx = this;

        GamesAPI.getAllGames().then(function(data)
        {
            ctx.setState({
                allGameData: data
            });

            ctx.onSelectionUpdated([{ index: 0, highlighted: true, title: ctx.state.allGameData[0].title }]);
        });
    }

    getItemTitles()
    {
        return [ "All games (53)", "Something", "Else" ];
    }

    getTestGameNames()
    {
        let charset = "abcdefghijklmnopqrstuvwxyz";
        let games = [];

        for(let i = 0; i < 30; i++)
        {
            let str = "";
            let rlen = 5 + Math.floor(Math.random() * 20);

            for(let j = 0; j < rlen; j++)
                str += charset[Math.floor(Math.random() * charset.length)];

            games.push(str);
        }

        return games;
    }

    onBackPressed(event)
    {
        this.props.navigate("..");
    }

    onSelectionUpdated(selection)
    {
        this.setState({ shownGame: selection[0] });
    }

    getShownSplashScreen()
    {
        if (this.state.shownGame == null)
            return null;

        const index = this.state.shownGame.index;
        const shownGame = this.state.allGameData[index];

        const image = strapiConfig.mediaBaseURL + shownGame.splashScreen.url;

        return <img src={image}/>
    }

    getDefaultGame()
    {
        return <>
            <main>
                <h1>Select a game</h1>
                <h2> </h2>
                <p>
                    Select a game using the joystick and by pressing A.
                </p>
            </main>
            <footer>
            </footer>
        </>
    }

    onPlayPressed(event)
    {
        if (this.state.shownGame == null)
            return;

        const index = this.state.shownGame.index;
        const shownGame = this.state.allGameData[index];
        
        window.location.href = shownGame.gameUrl;
    }

    getShownGame()
    {
        if(this.state.shownGame == null)
            return this.getDefaultGame();

        const index = this.state.shownGame.index;
        const shownGame = this.state.allGameData[index];

        const image = strapiConfig.mediaBaseURL + shownGame.splashScreen.url;

        return <>
            <main>
                <h1>{shownGame?.title}</h1>
                <h2>Authors: {shownGame.gameAuthors.map(x => x.name).join(", ")}</h2>
                <Stack orientation="horizontal">
                    {shownGame.gameGenres.map((x,i) => {
                        return <Label key={i} faded title={x.name}/>
                    })}
                </Stack>
                <p>
                    {shownGame?.description}
                </p>
            </main>
            <footer>
                <Stack orientation="horizontal">
                    {/* <Button tabIndex2D={{ x: 3, y: 20 }} title="Leaderboard" /> */}
                    <Button onEnterPressed={this.onPlayPressed.bind(this)} style={2} tabIndex2D={{ x: 4, y: 20 }} title="> Play" />
                </Stack>
            </footer>
        </>
    }

    getGameNames()
    {
        if (!this.state.allGameData || this.state.allGameData.length <= 0)
            return [ " " ];

        // console.log(this.state.allGameData.map(x => x.title));

        return this.state.allGameData.map(x => x.title);
    }

    render()
    {
        return <div className="games-page page">
            <CRTOverlay shown={pageText.global.showCRTFilter}/>
            <div className="left-pane">
                <header>
                    <Stack orientation="vertical">
                        <h1>Games</h1>
                        {/* <Switcher tabIndex2D={{ x: 0, y: 0 }} items={this.getItemTitles()} /> */}
                        {/* <Switcher tabIndex2D={{ x: 0, y: 1 }} items={["Page 1", "Page 2", "Page 3"]} /> */}
                    </Stack>
                </header>
                <main>
                    <GridMenu onSelectionUpdated={this.onSelectionUpdated.bind(this)} tabIndex2D={{ x: 0, y: 2 }} columns={2} items={this.getGameNames()} />
                </main>
                <footer>
                    <div className="controls">
                        <h1>Controls</h1>
                        <Stack dividers orientation="horizontal">
                            <Stack orientation="vertical">
                                <ControlIcon color={4} control="↑" title="Up" />
                                <ControlIcon color={4} control="↓" title="Down" />
                            </Stack>
                            <Stack orientation="vertical">
                                <ControlIcon color={4} control="<" title="Left" />
                                <ControlIcon color={4} control=">" title="Right" />
                            </Stack>
                            <Stack orientation="vertical">
                                <ControlIcon color={2} control="A" title="Select game" />
                                {/* <ControlIcon color={2} control="Start" title="Select game" /> */}
                            </Stack>
                        </Stack>
                    </div>
                    <Button onEnterPressed={this.onBackPressed.bind(this)} title="< Go back" tabIndex2D={{ x: 0, y: 20 }} />
                </footer>
            </div>
            <div className="right-pane">
                <header>
                    {this.getShownSplashScreen()}
                </header>
                {this.getShownGame()}
            </div>
        </div>
    }
}

export default (props) => (
    <GamesPage {...props} navigate={useNavigate()}/>
)