import React from "react";
import { warn } from "../common/utils";

export default class Label extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        let classes = [];

        if(!this.props?.title)
            warn("Tried to render Label component but title not supplied.");

        if(this.props?.faded)
            classes.push("faded");
            
        if(this.props?.error)
            classes.push("error");

        return <div className={`label ${classes.join(" ")}`}>
            {this.props?.title}
        </div>
    }
}