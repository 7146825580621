import React from "react";

export default class CRTOverlay extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        if(this.props?.shown === false)
            return null;

        return <>
            <div className="crt-overlay"></div>
            <div className="vignette"></div>
        </>
    }
}