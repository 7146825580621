import React from "react";

export default class Stack extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        let classes = ["horizontal"];

        if(this.props?.orientation != "horizontal")
            classes[0] = "vertical";

        if(this.props?.inline)
            classes.push("inline")

        if(this.props?.dividers)
            classes.push("divided");

        return <div className={`stack ${classes.join(" ")}`}>
            {this.props.children}
        </div>
    }
}