import _ from "lodash";
import React from "react";
import { makeNavigable } from "./makeNavigable.jsx";
import { isEventMine, isKey } from "../common/utils.js";
import MenuItem from "./MenuItem.jsx";

class Confirmation extends React.Component 
{
    constructor(props) 
    {
        super(props);
    }

    componentDidMount() 
    {
        document.addEventListener("arrowpress", this.onArrowPress.bind(this))
    }

    onArrowPress(event) 
    {
        //Is this event not this element? Just return
        if (!isEventMine(event, this))
            return;

        //Otherwise.. emit to navigatable frame
        // document.navigatableFrame.onPromptedNavigationEvent(event, this);
    }

    toHTMLProp(key, value) 
    {
        return {
            [`data-${key}`]: value
        }
    }

    render() 
    {
        if (this.props?.tabIndex2D == null)
            return warn("Tab index 2D not supplied to Confirmation on rendering.");

        if (this.props?.options == null || this.props.options?.length <= 1)
            return warn("Options given to confirmation are invalid: I need at least two.");

        let htmlProps = {
            ...this.toHTMLProp("ti-x", this.props.tabIndex2D.x),
            ...this.toHTMLProp("ti-y", this.props.tabIndex2D.y),
        };

        const ti = this.props.tabIndex2D;

        let classes = [];
        classes = classes.join(" ");

        let title = `(${this.props.tabIndex2D.x}, ${this.props.tabIndex2D.y})`;

        return <div {...htmlProps} title={title} className={`confirmation ${classes}`}>
            <MenuItem tabIndex2D={{ x: ti.x + 0, y: ti.y }} title={this.props.options[0]} />
            <div className="divider">/</div>
            <MenuItem tabIndex2D={{ x: ti.x + 1, y: ti.y }} title={this.props.options[1]} />
        </div>
    }
}

export default makeNavigable(Confirmation);