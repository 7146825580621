import axios from "axios";
import strapiConfig from '../config/api/strapiConfig';

export default
{
    getConfig()
    {
        //Build request URL
        const requestURL = strapiConfig.baseURL + strapiConfig.endpoints.mainMenuConfig;

        //Send it
        return new Promise((resolve, reject) => {
            axios.get(requestURL, strapiConfig.requestConfig).then(function (response) 
            {
                if (response.status != 200)
                    return reject(`Invalid status: ${response.status}`);

                const responseData = response.data;
                const returnedData = responseData.data;

                return resolve(returnedData.attributes);
            });
        });
    }
}