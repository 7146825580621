import React from "react";
import Button from "../arcadeui/js/components/Button.jsx";
import Stack from "../arcadeui/js/components/Stack.jsx";
import Switcher from "../arcadeui/js/components/Switcher.jsx";

//Router stuff
import { useNavigate } from "react-router";

import pageText from "../config/pageText.js";
import CRTOverlay from "../arcadeui/js/components/CRTOverlay.jsx";
const config = pageText.aboutPage;

//Api related stuff
import AboutPagesAPI from '../models/AboutPages';

class AboutPage extends React.Component
{
    constructor(props)
    {
        super(props);

        AboutPagesAPI.getAllPages().then(((data) => 
        {
            this.setState({ items: data });

        }).bind(this));

        this.state = {
            items: [],
            currentIndex: 0
        };

        this.items = [
            "What is this?".toUpperCase(),
            "Previous projects".toUpperCase(),
            "Getting involved".toUpperCase()
        ]
    }

    onBackPressed(event)
    {
        this.props.navigate("..");
    }

    onPageSwitched(index)
    {
        this.setState({ currentIndex: index });
    }

    getPageDescription()
    {
        if(this.state.items.length <= 0)
            return null;

        return this.state.items[this.state.currentIndex].attributes.description;
    }

    render()
    {
        return <div className="about-page page">
            <CRTOverlay shown={pageText.global.showCRTFilter}/>
            <div className="left-pane">
                <header>
                    <img src="assets/logo.png"/>
                </header>
                <main>
                    <h1>About</h1>
                    <div className="info">
                        <Switcher onIndexChanged={this.onPageSwitched.bind(this)} items={this.state.items.map(x => x.attributes.title.toUpperCase())} tabIndex2D={{ x: 0, y: 0 }} />
                        <p>{this.getPageDescription()}</p>
                    </div>
                </main>
                <footer>
                    <Stack orientation="horizontal">
                        <p>{config.footerNotice}</p>
                        <Button onEnterPressed={this.onBackPressed.bind(this)} title="< Go back" tabIndex2D={{ x: 0, y: 1 }} />
                    </Stack>
                </footer>
            </div>
            <div className="right-pane">
                <h1>Credits</h1>
                <div className="credits-scroller">
                    <div className="content">

                    {config.credits.map((block, i) => {

                        if(block?.para)
                            return <div key={i} className="block para">
                                <p>{block.para}</p>
                            </div>

                        if (block?.gap)
                            return <div key={i} className="block gap">
                                <p>----------------------</p>
                            </div>

                        if (block?.img)
                            return <div key={i} className="block gap">
                                <img src={block.img}/>
                            </div>

                        let classes = [];

                        if(block?.big)
                            classes.push("big");

                        return <div key={i} className={`block ${classes.join(" ")}`}>
                            <h2>{block.title}</h2>
                            {block.name.map(name => <><h3>{name}</h3></> )}
                        </div>
                    })}
                    </div>
                </div>
                <footer>
                    <img src="assets/intlab.png"/>
                    <img src="assets/uolwhite.png"/>
                </footer>
            </div>
        </div>;
    }
}

export default (props) => (
    <AboutPage {...props} navigate={useNavigate()}/>
)