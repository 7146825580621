import React from "react";
import { warn } from "../common/utils";

export default class Statistic extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        if(this.props?.title == null || this.props?.value == null)
            return warn("Tried to render Statistic component but it has either no title or no value prop.");

        let classes = [];
        let header = <header>{this.props?.title}</header>;
        let footer = <footer>{this.props?.value}</footer>;

        //Value first? swap elements
        if(this.props?.valueFirst)
        {
            let tmp = header;
            header = footer;
            footer = tmp;

            classes.push("value-first");
        }
        

        if(this.props?.noBorder)
            classes.push("no-border");

        if(this.props?.dimmed)
            classes.push("dimmed");

        return <div className={`statistic ${classes.join(" ")}`}>
            {header}
            {footer}            
        </div>
    }
}