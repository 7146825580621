import React from 'react'
import ReactDOM from 'react-dom'

import componentConfig from '../../config/components';
const config = componentConfig.navigable;

export const makeNavigable = (Component) =>
{
    return class ArcadeUIHOC extends React.Component 
    {
        constructor(props)
        {
            super(props);

            this.ref = React.createRef();

            this.state = {
                focused: false
            }

            this.onFocus   = this.onFocus.bind(this);
            this.onUnfocus = this.onUnfocus.bind(this);

            this.onArrowPressEventName = "arrowpress";
            this.onEnterPressEventName = "enterpress";
        }

        onFocus()
        {
            this.setState({ focused: true });

            document.navigatableFrame?.onFocusChange(this);
        }

        onUnfocus()
        {
            this.setState({ focused: false });
        }

        componentDidMount()
        {
            //Add events
            this.ref?.current?.addEventListener("focus", this.onFocus);
            this.ref?.current?.addEventListener("blur",  this.onUnfocus);

            //Register with navigatable frame
            document.navigatableFrame?.registerNavigableComponent(this);
        }

        componentWillUnmount()
        {
            //Unregister this component
            document.navigatableFrame?.unregisterNavigableComponent(this);
        }

        onEnterDown(event)
        {
            //Prevent default
            event.preventDefault();

            //Otherwise dispatch event
            document.dispatchEvent(new CustomEvent(this.onEnterPressEventName,
            {
                detail: {
                    thisElement: this.ref?.current,
                    thisComponent: this
                }
            }));
        }

        onKeyDown(event)
        {
            if(event.keyCode == 13)
                return this.onEnterDown(event);

            //Arrow keys doesnt include this key? Get out of here
            if(!config.arrowKeys.includes(event.which))
                return;

            //Otherwise, prevent default
            event.preventDefault();

            //Pass onto onArrowPress
            document.dispatchEvent(new CustomEvent(this.onArrowPressEventName, 
            { 
                detail: {
                    which: event.which,
                    thisElement: this.ref?.current
                }
            }));
        }

        render()
        {
            //Extra props to pass
            let extraProps = {
                selected: false,
                wrapperRef: this.ref,
                //--
                onWrapperFocus: this.onFocusEvent,
                onWrapperUnfocus: this.onUnfocusEvent,
                //--
                onArrowPress: this.onArrowPressEvent
            };
            
            //Is this element in focus?
            if(this.state.focused)
                extraProps.selected = true;

            return <span tabIndex={0} className="navigable-wrapper" ref={this.ref} onKeyDown={this.onKeyDown.bind(this)}>
                <Component {...this.props} {...extraProps} />
            </span>
        }
    }
}