import React from "react";
import ControlIcon from "../arcadeui/js/components/ControlIcon.jsx";
import Menu from "../arcadeui/js/components/Menu.jsx";
import Stack from "../arcadeui/js/components/Stack.jsx";

//Router stuff
import { useNavigate } from 'react-router-dom';

//Config
import menuConfig from '../config/menu.js';
import pageText from "../config/pageText.js";
import CRTOverlay from "../arcadeui/js/components/CRTOverlay.jsx";
const config = pageText.mainMenu;

import MainMenuAPI from '../models/MainMenuConfig'
import strapiConfig from "../config/api/strapiConfig.js";

class MainMenu extends React.Component 
{
    constructor(props) 
    {
        super(props);

        this.state = {
            logoImage: "",
            menuItems: []
        };

        MainMenuAPI.getConfig().then((function (data) 
        {
            // const videoSources = data.backgrounds.data.map(x => x.attributes);

            this.setState({
                menuItems: data.menu_items.data.map(x => x.attributes),
                logoImage: strapiConfig.mediaBaseURL + data.logoImage.data.attributes.url,
                backgroundVideos: data.backgroundVideos.data.map(x => x.attributes)
            })

        }).bind(this));
    }

    getRandomVideo()
    {
        const randomIndex = Math.floor(Math.random() * this.state.backgroundVideos?.length);

        const videos = this.state.backgroundVideos.map((x, i) => {
            return <source key={i} src={strapiConfig.mediaBaseURL + x.url} type={x.mime}/>
        });

        return videos[randomIndex];
    }

    getBackgroundVideoSources()
    {
        if(this.state.backgroundVideos == null || this.state.backgroundVideos.length <= 0)
            return null;
        
        return this.getRandomVideo();
            
        // return this.state.backgroundVideos.map((x, i) => {
        //     return <source key={i} src={strapiConfig.mediaBaseURL + x.attributes.url} type={x.mime}/>
        // })
    }

    getMenuItems()
    {
        if(this.state.menuItems.length > 0)
        {
            return this.state.menuItems.map(x => x.title)
        }

        return menuConfig.navigation.mainmenu.map(elem => {
            return elem.title
        })
    }

    onEnterPressedItem(data, index)
    {
        if(this.state.menuItems.length > 0)
        {
            const item = this.state.menuItems[index];
            const target = item.target;

            this.props?.navigate(target);

            return;
        }

        const item = menuConfig.navigation.mainmenu[index];
        const target = item.target;

        // console.log(this.props.history);
        this.props?.navigate(target);
    }

    render() 
    {
        return <div className="main-menu page">
            <CRTOverlay shown={pageText.global.showCRTFilter} />
            <div className="left-pane">
                <header>
                    <img src={this.state.logoImage || "assets/logo.png"}/>
                </header>
                <Menu onEnterPressedItem={this.onEnterPressedItem.bind(this)} orientation="vertical" tabIndex2D={{ x: 0, y: 0 }} items={this.getMenuItems()} />
                <footer>
                    <h1>Controls</h1>
                    <Stack orientation="horizontal" dividers>
                        <ControlIcon color={2} control="A" title="Select" />
                        <ControlIcon color={4} control="↑" title="Move up" />
                        <ControlIcon color={4} control="↓" title="Move down" />
                    </Stack>
                    <p>{config.copyrightNotice}</p>
                </footer>
            </div>
            <div className="right-pane">
                <video loop autoPlay muted preload="auto" playsInline>
                    {this.getBackgroundVideoSources()}
                </video>
            </div>
        </div>
    }
}


export default (props) => (
    <MainMenu 
        {...props}
        navigate={useNavigate()}
    />
);