export default 
{
    switcher: {
        leftGlyph:  "<",
        rightGlyph: ">"
    },

    navigable: {
        leftKey: 37,
        rightKey: 39,
        upKey: 38,
        downKey: 40,
        arrowKeys: [ 37, 39, 38, 40 ]
    }
}