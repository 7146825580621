import React from "react";
import ReactHtmlParser from 'react-html-parser';

import { useNavigate } from "react-router";
import { isEventMine } from "../arcadeui/js/common/utils.js";
import { makeNavigable } from "../arcadeui/js/components/makeNavigable.jsx";
import Menu from "../arcadeui/js/components/Menu.jsx";
import CRTOverlay from "../arcadeui/js/components/CRTOverlay.jsx";

import pageText from "../config/pageText.js";
const config = pageText.startPage;

import strapiConfig from "../config/api/strapiConfig.js";
import SplashScreenAPI from "../models/SplashScreenConfig"

class StartPage extends React.Component
{
    constructor(props)
    {
        super(props);

        this.kbdFunc = this.onKeyPress.bind(this);

        this.state = {
            title: "",
            subtitle: "",
            logoImage: "",
            backgroundVideos: []
        }

        SplashScreenAPI.getConfig().then((function(data)
        {
            const videoSources = data.backgrounds.data.map(x => x.attributes);

            this.setState({
                title: data.title,
                subtitle: data.subtitle,
                logoImage: strapiConfig.mediaBaseURL + data.logoImage.data.attributes.url,
                backgroundVideos: videoSources
            })

        }).bind(this));
    }

    componentDidMount() 
    {
        document.addEventListener("keydown", this.kbdFunc);
    }

    componentWillUnmount()
    {
        document.removeEventListener("keydown", this.kbdFunc);
    }

    onKeyPress(event)
    {
        if(event.code == "Enter")
            this.props.navigate("arcade");
    }

    getLogoImage()
    {
        if(config.showLogo)
            return <img src={this.state.logoImage || config.logoPath} />

        return null;
    }

    getSubtitle()
    {
        const htmlSrc = this.state.subtitle.replaceAll("[", "<kbd>").replaceAll("]", "</kbd>");

        return ReactHtmlParser(htmlSrc);
    }

    getVideoURL()
    {
        return this.state.backgroundVideos[0];
    }

    getRandomVideo()
    {
        const randomIndex = Math.floor(Math.random() * this.state.backgroundVideos?.length);

        const videos = this.state.backgroundVideos.map((x, i) => {
            return <source key={i} src={strapiConfig.mediaBaseURL + x.url} type={x.mime}/>
        });

        return videos[randomIndex];
    }

    render()
    {
        return <div className="start-page page">
            <CRTOverlay shown={pageText.global.showCRTFilter} />
            <div className="video-overlay">
                <span>
                    <video loop autoPlay muted preload="auto" playsInline>
                        {this.getRandomVideo()}
                    </video>
                </span>
            </div>
            <div className="centre-box">
                {this.getLogoImage()}
                <div className="content">
                    <h1>{this.state.title || config.title}</h1>
                    <p>{this.getSubtitle() || config.subtitle}</p>
                </div>
            </div>
        </div>
    }
}

export default (props) => (
    <StartPage {...props} navigate={useNavigate()} />
);