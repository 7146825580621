//Component config
import componentConfig from "../../config/components";


/**
 * Provides a basic wrap function, max and min must be positive and 
 * such that min > max
 * 
 * @param {*} newValue
 * @param {*} max
 * @param {*} min
 * 
 * @returns
 */
export function wrapValue(newValue, max, min=0)
{
    //The new value bigger than max? If so, return min
    if(newValue >= max)
        return min;
    
    //The new value is smaller than min? If so, return max
    else if(newValue < min)
        return max - 1;
    
    //Otherwise no clamp is needed, return new value
    return newValue;
}

/**
 * Checks if a key's charCode matches up with the ones specified in 
 * the navigable key config.
 * 
 * @param {*} key 
 * @returns 
 */
export const isKey = (key) =>
{
    return { 
        left:  (key == componentConfig.navigable.leftKey),
        right: (key == componentConfig.navigable.rightKey),
        up:    (key == componentConfig.navigable.upKey),
        down:  (key == componentConfig.navigable.downKey)
    }
}

/**
 * Tests whether a document dispatched event is actually intended for this navigable component,
 * defined with makeNavigable(...)
 * 
 * @param {*} event 
 * @param {*} context 
 * @returns 
 */
export function isEventMine(event, context)
{
    //Any of these null? return false
    if(context?.props?.wrapperRef?.current == null)
        return false;

    //Otherwise, return if it is actually belonging to this element
    return (event.detail.thisElement == context.props?.wrapperRef?.current);
}

/**
 * Warns the user of something.
 * 
 * @param {*} text 
 * @returns 
 */
export const warn = (text) => console.warn(`[arcadeui]: ${text}`);