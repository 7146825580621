import React from "react";
import { makeNavigable } from "./makeNavigable.jsx";
import { isEventMine, isKey } from "../common/utils.js";

class MenuItem extends React.Component
{
    constructor(props)
    {
        super(props);
    }

    toHTMLProp(key, value)
    {
        return {
            [`data-${key}`]: value
        }
    }

    componentDidMount()
    {
        document.addEventListener("arrowpress", this.onArrowPress.bind(this))
        document.addEventListener("enterpress", this.onEnterPress.bind(this))
    }

    onEnterPress(event)
    {
        //Event not mine? Get out
        if(!isEventMine(event, this))
            return;

        if(this.props?.onEnterPressed != null)
            this.props.onEnterPressed(event);
    }

    onArrowPress(event)
    {
        //Is this event not this element? Just return
        if (!isEventMine(event, this))
            return;

        //Left pressed? Capture event
        // if (isKey(event.detail.which).left)
            // console.log(`this is ti ${this.props.tabIndex2D}`);

        //Otherwise.. emit to navigatable frame
        document.navigatableFrame.onPromptedNavigationEvent(event, this);

        if(this.props?.onArrowPressed != null)
            this.props.onArrowPressed(event);
    }

    onClick()
    {
        if(this.props?.onClickItem != null)
            this.props.onClickItem(this);
    }

    render()
    {
        let htmlProps = {
            ...this.toHTMLProp("ti-x", this.props.tabIndex2D.x),
            ...this.toHTMLProp("ti-y", this.props.tabIndex2D.y),
        };

        let classes = [ ];

        if(this.props.selected)
            classes.push("selected");

        if(this.props.highlighted)
            classes.push("highlighted");

        classes = classes.join(" ");

        return <div onClick={this.onClick.bind(this)} {...htmlProps} title={`(${this.props.tabIndex2D.x}, ${this.props.tabIndex2D.y})`} className={`menu-item ${classes}`}>
            {this.props.title}
        </div>
    }
}

export default makeNavigable(MenuItem);